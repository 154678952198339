import * as React from 'react';
import { MainButton } from "../cmp/Button";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { BackHeader } from "../cmp/BackHeader";
import {encryptSeedPhrase, loadWallet, saveWallet} from "../util/crypto";
import {useContext} from "react";
import {useLocalWallet} from "../context/WalletContext";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    background: 'white',
  },
  pinCodeDisplay: {
    textAlign: 'center',
    fontSize: '60px',
    letterSpacing: '15px',
  },
  bottomContainer: {

    textAlign: 'center',
  },
  keypad: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  key: {
    padding: '15px',
    fontSize: '24px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    margin: '10px auto',
    transition: 'color 0.2s', // Анимация синения только для содержимого кружка
  },
  keyActive: {
    color: '#1e90ff', // Синий цвет при нажатии
  },
  keyZero: {
    gridColumn: '2', // Центрирование ноля
  },
  errorPlaceholder: {
    height: '20px',
    color: 'transparent', // Зарезервированное место под ошибку
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '15px',
  },
};

export default function EnterPinCodePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [pinCode, setPinCode] = React.useState('');
  const [activeKey, setActiveKey] = React.useState(null);
  const [isWrong, setWrong] = React.useState(null);
  const { localWallet, setLocalWallet } = useLocalWallet();


  const handleKeyClick = (digit) => {
    const pin = pinCode;
    if (pin.length < 4) {
      setPinCode(pin + digit);
      setActiveKey(digit);
      setTimeout(() => setActiveKey(null), 200); // Сбрасывает анимацию нажатия
    }
    setWrong(false);
  };

  const handleKeyRemove = () => {
    const pin = pinCode;
    setPinCode(pin.slice(0, -1));
  }

  const handleConfirm = () => {
    const seed = loadWallet(pinCode);
    if (seed) {
      setLocalWallet(seed);
      navigate('/dashboard?dest='+searchParams.get("dest"))
    } else {
      setWrong(true);
      setPinCode('');
    }
  };



  return (
    <div style={styles.container}>
      <BackHeader>Введите пин-код</BackHeader>
      <div style={styles.pinCodeDisplay}>
        &nbsp;{pinCode.replace(/./g, '•')}
      </div>
      <div style={styles.bottomContainer}>
        <div style={styles.keypad}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
            <div key={digit} style={{...styles.key, ...(activeKey === digit && styles.keyActive)}} onClick={() => handleKeyClick(digit.toString())}>
              {digit}
            </div>
          ))}
          <div style={{ ...styles.key, ...styles.keyZero }} onClick={() => handleKeyClick('0')}>0</div>
          <div style={{ ...styles.key}} onClick={() => handleKeyRemove()}>{"<"}</div>
        </div>
        {isWrong && <div style={ styles.error }>Неверный пин-код</div>}
        <MainButton onClick={handleConfirm}>
          {'Войти'}
        </MainButton>
      </div>
    </div>
  );
}



