import React, {useEffect, useState} from 'react';

const dropdownStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  dropdown: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  },
  dropdownToggle: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 0.2s ease',
    borderRadius: '4px',
    justifyContent: 'space-between',
  },
  dropdownOptionImage: {
    width: '40px',
    height: '40px',
    marginRight: '12px',
    borderRadius: '50%',
  },
  dropdownOptionLabel: {
    flexGrow: 1,
    paddingLeft: '10px',
  },
  dropdownMenu: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: 0,
    margin: 0,
    padding: '12px 0',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderTop: 'none',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    listStyle: 'none',
    zIndex: 1,
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(-12px)',
    transition: 'opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
  },
  dropdownMenuOpen: {
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)',
  },
  dropdownMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  },
};


export default function Dropdown({options, label, startingOption, onChange}) {
  //console.log('Dropdown', startingOption);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (startingOption) {
      setSelectedOption(startingOption);
    }
  }, [startingOption]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsDropdownOpen(false);
  };

  return (
    <>
      {label && <div>{label}</div>}
      <div style={dropdownStyles.container}>
        <div style={dropdownStyles.dropdown}>
          <div style={dropdownStyles.dropdownToggle} onClick={toggleDropdown}>

            {selectedOption && selectedOption.image}
            <span style={dropdownStyles.dropdownOptionLabel}>
              {selectedOption ? selectedOption.label : 'Select an option'}
            </span>
            <svg aria-hidden="true" className="svg-icon iconArrowDown" width="18" height="18" viewBox="0 0 18 18"><path d="M1 6h16l-8 8-8-8Z"></path></svg>
          </div>
          <ul style={{...dropdownStyles.dropdownMenu, ...(isDropdownOpen ? dropdownStyles.dropdownMenuOpen : {})}}>
            {options.map((option, index) => (
              <li key={index} style={dropdownStyles.dropdownMenuItem} onClick={() => selectOption(option)}>
                {option.image}
                <span style={dropdownStyles.dropdownOptionLabel}>{option.label}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
