import CryptoJS from 'crypto-js';
import {ethers} from 'ethers';


export const ERC20_ABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_spender",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "name": "",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_from",
        "type": "address"
      },
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "name": "",
        "type": "uint8"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      },
      {
        "name": "_spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "name": "",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "payable": true,
    "stateMutability": "payable",
    "type": "fallback"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  }
];

const encryptAES = (text, key) => {
  return CryptoJS.AES.encrypt(text, key).toString();
};


const decryptAES = (encryptedBase64, key) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
  if (decrypted) {
    try {
      console.log(decrypted);
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }
  return null;
};

export function encryptSeedPhrase(seedPhrase, pin) {
  const data = {
    date: new Date().getTime(),
    seed: seedPhrase,
  }

  return encryptAES(JSON.stringify(data), pin);
}


export function decryptSeedPhrase(string, pin) {
  const data = decryptAES(string, pin);
  console.log(data)

  if (data === null) return null;

  try {
    const json = JSON.parse(data);
    if (json.date && json.seed) {
      return json.seed;
    }
  } catch (error) {
    return null;
  }
}


export function saveWallet({privateKey, seedPhrase, derivationPath}, pin) {
  const wallet = {}
  if (privateKey) {
    wallet.privateKey = privateKey;
    wallet.isPrivate = true;
  } else {
    wallet.seedPhrase = seedPhrase;
    wallet.derivationPath = derivationPath;
    wallet.isPrivate = false;
  }

  const encrypted = encryptSeedPhrase(wallet, pin);
  localStorage.setItem('LOCAL_WALLET', encrypted);
  localStorage.removeItem('USER_COINS');
  localStorage.removeItem('metamask');
}

export function loadWallet(pin) {
  const encrypted = localStorage.getItem('LOCAL_WALLET');
  if (encrypted) {
    return decryptSeedPhrase(encrypted, pin);
  }
  return null;
}

export function etherWallet(localWallet) {
  try {
    if (localWallet.privateKey) {
      return new ethers.Wallet(localWallet.privateKey)
    } else {
      return ethers.HDNodeWallet.fromMnemonic(ethers.Mnemonic.fromPhrase(localWallet.seedPhrase), localWallet.derivationPath);
    }
  } catch (e) {
    return null;
  }
}

export function isLocalWalletSaved() {
  return !!localStorage.getItem('LOCAL_WALLET');
}

export function clearLocalWallet() {
  localStorage.removeItem("metamask");
  localStorage.removeItem("USER_COINS");
  localStorage.removeItem('LOCAL_WALLET');
}

export const NETWORKS = [
  {
    name: "Binance Mainnet",
    provider: new ethers.JsonRpcProvider("https://bsc-dataseed.binance.org"),
    token: "BNB",
    chainId: 56,
    api: "https://api.bscscan.com/api",
    apiKey: "",
    tokenWrapperAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    pancakeSwapRouterAddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    usdtAddress: '0x55d398326f99059ff775485246999027b3197955',
    initialCoins: [
      {code: "BSO", address: '0xFBA3B64fc886aA48CE53D700A3cd1CDFc7A1DAC4', decimal: 18, icon: require(`../assets/icons/BSO.png`)},
      {code: "BUSD", address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', decimal: 18},
      {code: "USDT", address: '0x55d398326f99059ff775485246999027b3197955', decimal: 18},
    ]
  },{
  name: "Binance Testnet",
  provider: new ethers.JsonRpcProvider("https://data-seed-prebsc-1-s1.bnbchain.org:8545"),
  token: "BNB",
  chainId: 97,
  api: "https://api-testnet.bscscan.com/api",
  apiKey: "P3DW52BE6JQQVST1W1VKQQ1ITCI1MM91JK",
  tokenWrapperAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  pancakeSwapRouterAddress: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  usdtAddress: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  initialCoins: [
    {code: "USDC", address: '0x64544969ed7EBf5f083679233325356EbE738930', decimal: 18},
    {code: "USDT", address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd', decimal: 18},
  ]

}
];

export const getUserTokens = async (network, address) => {
  const url = `${network.api}?module=account&action=tokentx&address=${address}&tag=latest&apikey=${network.apiKey}`;
  const response = await fetch(url);
  const json = await response.json();
  console.log(json.result)
  return json.result;
}

export async function getBEP20TransactionsByToken(network, walletAddress, tokenContractAddress) {
  const url = `${network.api}?module=account&action=tokentx&address=${walletAddress}&sort=asc&apikey=${network.apiKey}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.status === "1") {
    return data.result.filter(tx => tx.contractAddress.toLowerCase() === tokenContractAddress.toLowerCase());
  }
  return data;
}

export async function getBNBTransactions(network, walletAddress) {
  const url = `${network.api}?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=asc&apikey=${network.apiKey}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.status === "1") {
    return data.result;
  }
  return data;
}


export async function connectMetamask () {
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      if (Array.isArray(accounts) && accounts[0]) {
        return accounts[0];
      }
    } catch (e) {
      return null;
    }
  } else {
    window.open('https://metamask.app.link/dapp/wallet.bscosmo.io');
  }
}


