import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import Popup from "./Popup";
import {SmallText, TitleText} from "../cmp/Text";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}

export default function ExitPopup({address, onClose, onOk, okButtonText}) {

  return (
    <Popup header="Выход" onClose={onClose} onOk={onOk} okButtonText={okButtonText} isOkEnabled={true}>
      <div style={styles.container}>
        <TitleText>Вы уверены что хотите выйти из кошелька?</TitleText>
        <SmallText>Если вы не сохранили сид-фразу, то вы потеряете доступ к кошельку и всем своим средствам навсегда.</SmallText>
        <SmallText>Мы не храним сид-фразы и не можем восстановить доступ к кошельку.</SmallText>
        <SmallText>Никто не сможет восстановить доступ к кошельку, если вы потеряете сид-фразу.</SmallText>
      </div>
    </Popup>
  );
}
