import * as React from 'react';

const styles = {
  simpleButton: {
    margin: '10px',
    width: '90%',
    padding: '15px 0',
    fontSize: '16px',
    cursor: 'pointer',
    border: '1px solid #007BFF',
    background: 'white',
    color: '#007BFF',
    borderRadius: '25px',
  },
  mainButton: {
    margin: '10px',
    width: '90%',
    padding: '15px 0',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    background: '#007BFF',
    color: 'white',
    borderRadius: '25px',
  },
  disabled: {
    opacity: 0.5,
  }
}

export function SimpleButton(props) {
  return (
    <button {...props} style={{...styles.simpleButton, ...props.style}}>
      {props.children}
    </button>
  )
}

export function MainButton(props) {
  return (
    <button {...props} style={{...styles.mainButton, ...props.style, ...(props.disabled ? styles.disabled : {})}}>
      {props.children}
    </button>
  )
}

export function CircleButton({onClick, children, name}) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    <button onClick={onClick} style={{
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      backgroundColor: '#2196F3',
      color: '#fff',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '5px',
      fontWeight: '1000',
      fontSize: '30px'
    }}>
      {children}
    </button>
    {name}
  </div>
}
