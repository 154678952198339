import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import Popup from "./Popup";
import {SmallText, TitleText} from "../cmp/Text";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
}

export default function ExportSidPopup({seedPhrase, onClose}) {

  return (
    <Popup header="Экспорт сид-фразы" onClose={onClose}>
      <div style={styles.container}>
        <TitleText>Ваша сид фраза для восстановления</TitleText>
        <AddressLine address={seedPhrase}/>

        <div>
          <SmallText>Никто не сможет восстановить доступ к кошельку, если вы потеряете или забудете сид-фразу.</SmallText>
          <SmallText>Никому не сообщайте вашу сид-фразу, даже нам.</SmallText>
          <SmallText>Если ваша сид-фраза попадет к другому лицу он получит полный контроль над вашим кошельком и вашими деньгами.</SmallText>
        </div>
      </div>
    </Popup>
  );
}
