import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import Popup from "./Popup";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    height: '100%',
  },
}

export default function DepositPopup({address, onClose}) {

  return (
    <Popup header="Deposit" onClose={onClose}>
      <div style={styles.container}>
        <div style={{height: "auto", margin: "0 auto", maxWidth: 200, width: "100%"}}>
          <QRCode
            size={256}
            style={{height: "auto", maxWidth: "100%", width: "100%"}}
            value={address}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div>
          Написать предупреждение о том, что нельзя отправлять сюда токены, которые не поддерживаются сетью
        </div>
        <AddressLine address={address}/>
      </div>
    </Popup>
  );
}
