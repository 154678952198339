import * as React from 'react';

const styles = {
  title: {
    margin: '20px',
    fontSize: '20px',
    color: '#333',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '14px',
    color: '#666',
    textAlign: 'center',
  },
  small: {
    fontSize: '12px',
    color: '#666',
    textAlign: 'center',
  },
}

export function TitleText(props) {
  return (
    <div {...props} style={{...styles.title, ...props.style}}>
      {props.children}
    </div>
  )
}

export function SubtitleText(props) {
  return (
    <div {...props} style={{...styles.subtitle, ...props.style}}>
      {props.children}
    </div>
  )
}

export function SmallText(props) {
  return (
    <div {...props} style={{...styles.small, ...props.style}}>
      {props.children}
    </div>
  )
}
