export default function Fade() {

 return (
   <div style={{
        opacity: "0.5",
        position: "absolute",
        backgroundColor: "#000",
        width: "100%",
        height: "100%",
        top: "0",
        right: "0",
        zIndex: "1",
   }}></div>
 );
}
