import * as React from 'react';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    width: '100%',
  },

  input: {
    fontSize: '16px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'white',
    height: '62px',
    borderRadius: '4px',
  },
}

export function Input(props) {
  const id = Math.random().toString(36).substring(2, 15);
  return (
    <div style={styles.container}>
      <label htmlFor={id}>{props.label}</label>
      <input id={id} type={props.type || "text"} {...props} style={{...styles.input, ...props.style}}>
        {props.children}
      </input>
      {props.error && <div style={{color: 'red'}}>{props.error}</div>}
    </div>
  )
}


