import * as React from 'react';
import logo from "../assets/logo200.png";
import {MainButton, SimpleButton} from "../cmp/Button";
import {SmallText, SubtitleText, TitleText} from "../cmp/Text";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {connectMetamask, isLocalWalletSaved} from "../util/crypto";
import {AddressLine} from "../cmp/AddressLine";
import {notifyMarketplaceAboutNewWallet} from "../util/marketplace";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    background: 'white',
  },
  logoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
  },

  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFAF0', // Нежно-оранжевый цвет
    zIndex: 2,
    position: 'relative',
  },

};

export default function WelcomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [metamask, setMetamask] = useState(false);
  const [bsoBalance, setBsoBalance] = useState('');

  const handleImportWallet = () => {
    navigate('/wallet/import?xtoken=' + searchParams.get("xtoken"));
  };

  const handleCreateNew = () => {
    navigate('/wallet/create?xtoken=' + searchParams.get("xtoken"));
  };
  const handleMetamask = async () => {
    const metamask = await connectMetamask();

    localStorage.setItem("metamask", metamask);

    loadBalance(metamask);

    if (metamask && searchParams.get("xtoken") && searchParams.get("xtoken") !== 'null'){
      notifyMarketplaceAboutNewWallet(metamask, searchParams.get("xtoken"));
    }
    setMetamask(metamask);
  };

  const handleDisconnect = () => {
    localStorage.removeItem("metamask");
    setMetamask(false);
  }

  const loadBalance = (metamask) => {
    if (metamask) {
      fetch("https://bonuses.bscosmo.io/getBuyerBSOBalance?address="+metamask)
        .then((response) => response.json())
        .then((balance) => {
          setBsoBalance(`${balance.amount} BSO (${balance.amount_usd}$)`);
        });
    }
  }

  useEffect(() => {
    const wallet = isLocalWalletSaved()
    if (wallet) {
      navigate('/pin');
    }

    const metamask = localStorage.getItem("metamask");
    if (metamask){
      setMetamask(metamask);
      loadBalance(metamask);
    }
  }, []);


  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img src={logo} alt="Логотип" style={styles.logo}/>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={styles.wave}>
        <path fill="#FFFAF0"
              d="M0,32L48,53.3C96,75,192,117,288,149.3C384,181,480,203,576,213.3C672,224,768,224,864,197.3C960,171,1056,117,1152,106.7C1248,96,1344,160,1392,192L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
      <div style={styles.textContainer}>
        <TitleText style={styles.title}>Добро пожаловать в BSCOSMO!</TitleText>
        <SubtitleText style={styles.subtitle}>создайте кошелек прямо в приложении и управляйте своими криптовалютными активами безопасно и удобно.</SubtitleText>
        <MainButton onClick={handleCreateNew}>
          Создать новый кошелек
        </MainButton>
        <SimpleButton onClick={handleImportWallet}>
          Импортировать кошелек
        </SimpleButton>
        <SubtitleText style={styles.subtitle}>или используйте Metamask</SubtitleText>
        {!metamask && <SimpleButton onClick={handleMetamask}>
          {"Подключить Metamask"}
        </SimpleButton>}
        {metamask && <>
          <SubtitleText>Metamask подключен</SubtitleText>
          <AddressLine address={metamask}/>
          <a target="_blank" href={"https://bscosmo.io/buyer?address=" + metamask }>{'Перейти на вебсайт'}</a>
          <div>
            {bsoBalance}
          </div>
          <SimpleButton onClick={handleDisconnect}>
            {"Отключить"}
          </SimpleButton>
        </>
        }
        <SmallText style={styles.subtitle}>Продолжая, вы принимаете условия пользовательского соглашения</SmallText>
      </div>
    </div>
  );
}
