import React, {useState, useEffect} from 'react';
import Popup from "./Popup";
import {ethers} from "ethers";
import {ERC20_ABI} from "../util/crypto";
import {Input} from "../cmp/Input";


export default function ImportTokensPopup({onClose, onOk, okButtonText, provider}) {
  const [address, setAddress] = useState('');
  const [symbol, setSymbol] = useState('');
  const [decimals, setDecimals] = useState('');

  const fetchTokenDetails = async (contractAddress) => {
    try {
      const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);

      const [fetchedSymbol, fetchedDecimals] = await Promise.all([
        contract.symbol(),
        contract.decimals()
      ]);

      setSymbol(fetchedSymbol);
      setDecimals(parseInt(fetchedDecimals.toString()));
    } catch (e) {
      setSymbol('');
      setDecimals('');
    }
  };

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    fetchTokenDetails(newAddress);
  };

  const handleOk = () => {
    onOk({address, code: symbol, decimals});
  }

  return (
    <Popup header="Import new token" onClose={onClose} onOk={handleOk} okButtonText={okButtonText} isOkEnabled={true}>

      <Input
        label={"Адрес контракта"}
        type="text"
        placeholder="Enter token address"
        value={address}
        onChange={handleAddressChange}
      />

      <Input
        label={"Символ"}
        type="text"
        placeholder="Enter token symbol"
        value={symbol}
        onChange={(e) => setSymbol(e.target.value)}
      />

      <Input
        label={"Число десятичных знаков"}
        type="text"
        placeholder="Enter token decimals"
        value={decimals}
        onChange={(e) => setDecimals(e.target.value)}
      />
    </Popup>
  );
}
