import React, { createContext, useContext, useState } from 'react';

const WalletContext = createContext();

export function WalletProvider({ children }) {
  const [localWallet, setLocalWallet] = useState(null);

  return (
    <WalletContext.Provider value={{ localWallet, setLocalWallet }}>
      {children}
    </WalletContext.Provider>
  );
}

export function useLocalWallet() {
  const context = useContext(WalletContext);

  if (context === undefined) {
    throw new Error('useSeedPhrase must be used within a SeedPhraseProvider');
  }

  return context;
}
