import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import Popup from "./Popup";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}

export default function SwapPopup({address, onClose}) {

  return (
    <Popup header="Обмен" onClose={onClose}>
      <div style={styles.container}>
        Coming soon
      </div>
    </Popup>
  );
}
