import React, {useState, useEffect} from 'react';
import Popup from "./Popup";
import {ethers} from "ethers";
import {ERC20_ABI} from "../util/crypto";
import {AddressLine} from "../cmp/AddressLine";
import Dropdown from "../cmp/Dropdown";
import {Input} from "../cmp/Input";
import {CoinAvatar} from "../cmp/CoinAvatar";
import {isAddress} from "web3-validator";
import CrossRate from "../cmp/CrossRate";
import Loading from "../cmp/Loading";


function OptionLabel({coin, amount, inUsd}) {
  return (
    <span style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <span style={{
        paddingLeft: '10px',
      }}>
        <div>{amount} {coin.code}</div>
        <div style={{
          fontSize: '12px',
          color: '#ccc',
        }}>{inUsd}</div>
      </span>
    </span>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'column',
  },

  maxButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: "pointer", color: "#eaacac",
    borderTop: '1px solid rgb(204, 204, 204)',
    borderBottom: '1px solid rgb(204, 204, 204)',
    borderRight: '1px solid rgb(204, 204, 204)',
    fontSize: '16px',
    background: 'white',
    height: '64px',
    borderRadius: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginTop: '29px',
    padding: '0 10px',
  },
  sumInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  gasContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "200px",
    borderRadius: "4px",
    border: '1px solid rgb(204, 204, 204)',
    textAlign:"center",
  }

}

export default function WithdrawPopup({onClose, onOk, provider, wallet, coins, coinsBalance, startedCoin, network}) {

  const [options, setOptions] = useState([]);
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(null);
  const [addressTo, setAddressTo] = useState('');
  const [addressToError, setAddressToError] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [estimatedGas, setEstimatedGas] = useState(0);
  const [txHash, setTxHash] = useState(null);
  const [error, setError] = useState(null);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const opt = [];
    for (const coin of coins) {
      opt.push({
        label: <OptionLabel coin={coin} amount={coinsBalance[coin.code]} inUsd={<CrossRate amount={coinsBalance[coin.code]} network={network} coin={coin}/>}/>,
        value: coin.code,
        image: <CoinAvatar coin={coin}/>,
        origin: coin
      });
    }
    setOptions(opt);
  }, [coins, coinsBalance, network]);

  const handleOk = () => {
    transferFunds();
  }

  const setMax = () => {
    setAmount(coinsBalance[(selectedCoin?.origin || startedCoin).code]);
  }


  useEffect(() => {
    async function estimateGas(coin, addressTo, amount) {
      let gasEstimate;


      if (!coin.main) {
        const contract = new ethers.Contract(coin.address, ERC20_ABI, provider);
        gasEstimate = (await contract.transfer.estimateGas(addressTo, await contract.balanceOf(wallet.address), {from: wallet.address})) * 3n;
      } else {
        console.log('estimateGas', addressTo, amount)
        gasEstimate = await provider.estimateGas({
          to: addressTo,
          value: ethers.parseEther(amount.toString())
        });
      }

      console.log('gasEstimate', gasEstimate);
      setEstimatedGas(gasEstimate);
    }

    estimateGas(selectedCoin?.origin || startedCoin, getAddressTo() || "0x307137eEBB72426CAccE71C76D20240642bedC78", amount || 0);
  }, [addressTo, selectedCoin, amount, provider, startedCoin, network]);

  async function transferFunds() {
    if (!validate()) return;
    setError(null);
    setTxHash(null);
    setIsProcessing(true);
    try {
      let tx;
      const coin = selectedCoin?.origin || startedCoin;

      if (!coin.main) {
        const contract = new ethers.Contract(coin.address, ERC20_ABI, wallet);
        tx = await contract.transfer(addressTo, ethers.parseUnits(amount.toString(), coin.decimals));
      } else {
        tx = await wallet.sendTransaction({
          to: addressTo,
          value: ethers.parseEther(amount.toString())
        });
      }

      await tx.wait();

      onOk();

      setAddressTo('');
      setAmount(0);
      setIsProcessing(false);
      setTxHash(tx.hash);
      setError(null);
    } catch (err) {
      setError(err.message);
      setTxHash(null);
    }
  }

  const getAddressTo = () => {
    if (addressTo && isAddress(addressTo)) {
      return addressTo;
    } else {
      return null;
    }
  }


  useEffect(() => {
    validate();
  }, [addressTo, amount, selectedCoin]);
  const amountHandler = (e) => {
      const value = e.target.value;
      if (/^\d*\.?\d*$/.test(value)) {
        setAmount(value);
      }
  }

  const addressToHandler = (e) => {
    setAddressTo(e.target.value.trim());
  }

  const validate = () => {
    let isValid = true;
    if (!(selectedCoin?.origin || startedCoin).code) {
      isValid = false;
    }

    if (!getAddressTo()) {
      isValid = false;
      setAddressToError(addressTo?"Неправильный адрес":null);
    } else {
      setAddressToError(null);
    }

    if (amount <= 0 || amount > coinsBalance[(selectedCoin?.origin || startedCoin).code])  {
      isValid = false;
      setAmountError(amount?"Неправильная сумма":null)
    } else {
      setAmountError(null);
    }

    setIsSendButtonDisabled(!isValid);
    return isValid;
  }

  return (
    <Popup header="Вывод" onClose={onClose} onOk={handleOk} okButtonText={"Отправить"} isOkEnabled={!isSendButtonDisabled && !isProcessing}>
      <div style={styles.container}>

        <div>
          <Dropdown label={"Токен"} options={options} onChange={setSelectedCoin} startingOption={options.findLast(o=> o.value === startedCoin.code)}/>

          <div style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}>
            <Input
              label="Сумма"
              style={styles.sumInput}
              type="text"
              placeholder="Сумма перевода"
              value={amount}
              error={amountError}
              onChange={amountHandler}
            />
            <div style={styles.maxButton} onClick={setMax}>Max</div>
          </div>

          <Input
            label="Адрес получателя"
            type="text"
            placeholder="Адрес получателя"
            value={addressTo}
            error={addressToError}
            onChange={addressToHandler}
            style={{fontSize: "3.3vw"}}
          />
        </div>
        <div style={styles.gasContainer}>
          <div>Расчетный газ: <b>{ethers.formatUnits(estimatedGas, "gwei")}&nbsp;{network.token}<CrossRate toFixed={6} coin={{code:network.token, main:true}} network={network} amount={ethers.formatUnits(estimatedGas, "gwei")}/></b></div>

          <div style={{textAlign:"center", width: "100%"}}>
            {isProcessing && <Loading/>}
            <div>{txHash && <p>Операция завершена успешно, хэш транзакции:</p>}</div>
            {txHash && <AddressLine address={txHash.substring(0, 15)+"..."+txHash.substring(45)} />}
            {error && <p>Ошибка: {error}</p>}
          </div>
        </div>
      </div>
    </Popup>
  );
}
