import * as React from "react";
import ImageLoader from "react-loading-image/lib";
import Avatar from "react-avatar";

export function CoinAvatar({coin}) {
  return (
    <ImageLoader
      src={coin.icon || `https://static.metafi.codefi.network/api/v1/tokenIcons/56/${coin.address.toLowerCase()}.png`}
      loading={() => <Avatar name={coin.code} size="36" round="200px"/>}
      image={props => <Avatar {...props} size="36" round="200px"/>}
      error={() => <Avatar name={coin.code} size="36" round="200px"/>}
      width={20} height={20}
    />
  )
}
