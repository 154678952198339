import * as React from "react";
import {TitleText} from "./Text";
import {useNavigate} from "react-router-dom";

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 10px',
    background: '#FFFAF0',
    position: 'relative',
  },
}

export function BackHeader(props) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div style={styles.header}>
      <span style={styles.backButton} onClick={handleBack}>←</span>
      <TitleText>{props.children}</TitleText>
      <div/>
    </div>
  )
}
