import * as React from 'react';
import {MainButton} from "../cmp/Button";
import {SubtitleText, SmallText} from "../cmp/Text";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ethers} from "ethers";
import {BackHeader} from "../cmp/BackHeader";
import {useLocalWallet} from "../context/WalletContext";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'white',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 10px',
    background: '#FFFAF0',
    position: 'relative',
  },
  backButton: {
    cursor: 'pointer',
  },
  titleContainer: {
    padding: '20px',
    background: '#FFFAF0',
    textAlign: 'center',
  },
  seedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
  },
  seedItem: {
    borderRadius: '20px',
    padding: '10px 20px',
    margin: '5px',
    background: '#f0f0f0',
    border: '1px solid #ccc',
  },
  bottomContainer: {
    marginTop: 'auto',
    padding: '20px',
    textAlign: 'center',
  },
  copyButton: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
    marginBottom: '15px',
  },
};

export default function CreateWalletPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [seedPhraseState, setSeedPhraseState] = React.useState([]);

  const {setLocalWallet} = useLocalWallet();

  React.useEffect(() => {
    const mnemonic = ethers.Wallet.createRandom().mnemonic.phrase;
    setSeedPhraseState(mnemonic.split(' '));
  }, []);

  const handleNext = () => {
    setLocalWallet({seedPhrase:seedPhraseState.join(' '), derivationPath: process.env.REACT_APP_DERIVATION_PATH});
    navigate('/wallet/confirm?xtoken=' + searchParams.get("xtoken"));
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(seedPhraseState.join(' '));
  };

  return (
    <div style={styles.container}>
      <BackHeader>Шаг 1: Ваша секретная фраза</BackHeader>
      <div style={styles.titleContainer}>
        <SubtitleText>Запишите или скопируйте вашу секретную фразу</SubtitleText>
        <br/>
        <SmallText>Не делитесь этой фразой с кем-либо. Она нужна для восстановления доступа к вашему
          кошельку.</SmallText>
      </div>
      <div style={styles.seedContainer}>
        {seedPhraseState.map((word, index) => (
          <div key={index} style={styles.seedItem}>
            {word}
          </div>
        ))}
      </div>
      <div style={styles.bottomContainer}>
        <div style={styles.copyButton} onClick={handleCopyToClipboard}>
          Скопировать в буфер обмена
        </div>
        <MainButton onClick={handleNext}>
          Далее
        </MainButton>
      </div>
    </div>
  );
}
