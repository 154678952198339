import React from "react";
import Fade from "./Fade";
import {SimpleButton} from "./Button";
import ImportTokensPopup from "../popup/ImportTokensPopup";
import ExitPopup from "../popup/ExitPopup";
import {useNavigate} from "react-router-dom";
import SettingsPopup from "../popup/SettingsPopup";
import {useLocalWallet} from "../context/WalletContext";
import {clearLocalWallet} from "../util/crypto";

export default function BurgerMenu({refreshDashBoard}) {
  const navigate = useNavigate();
  const [opened, setOpened] = React.useState(false);
  const [isExitOpened, setIsExitOpened] = React.useState(false);
  const [isSettingsOpened, setIsSettingsOpened] = React.useState(false);
  const { localWallet, setLocalWallet } = useLocalWallet();


  const toggle = (e) => {
    setOpened(!opened);
  };

  const exitHandler = () => {
    clearLocalWallet();
    navigate('/');
  }

  const toggleExitPopup = () => {
    setIsExitOpened(!isExitOpened);
  }

  const toggleSettingsPopup = () => {
    setIsSettingsOpened(!isSettingsOpened);
  }

  const openExportSidPopup = () => {
    navigate('/pin?dest=export-sid');
  }

  const onSettingsClose = ({path}) => {
    setLocalWallet({...localWallet, derivationPath: path});
    refreshDashBoard();
    toggleSettingsPopup();
  }


  return (
    <>
      {isExitOpened && <ExitPopup onOk={exitHandler} okButtonText={'Выйти из кошелька'} onClose={toggleExitPopup}/>}
      {isSettingsOpened &&
        <SettingsPopup onOk={onSettingsClose} localWallet={localWallet} okButtonText={'Сохранить'} onClose={toggleSettingsPopup}/>}
      {opened && <Fade onClick={toggle}/>}
      <div style={{fontSize: "30px"}} onClick={toggle}>&#9776;</div>
      {opened && <div onClick={toggle} style={{
        position: "absolute",
        backgroundColor: "#ffffff",
        width: "70%",
        bottom: "0",
        top: "0",
        right: "0",
        zIndex: "10",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        animation: "slide 2s forwards",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "left",
          width: "100%",
        }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: "58px",
            borderBottom: "1px solid #ccc",
            alignItems: "flex-start",
          }}>
            <div style={{padding: "20px"}}>Меню</div>
            <div style={{fontSize: "30px", padding: "10px"}}>&#9776;</div>
          </div>
          <div onClick={openExportSidPopup}
               style={{padding: "20px", borderBottom: "1px solid #ccc", width: "100%"}}>Экспорт сид фразы
          </div>
          <div onClick={toggleSettingsPopup}
               style={{padding: "20px", borderBottom: "1px solid #ccc", width: "100%"}}>Настройки
          </div>

        </div>
        <SimpleButton onClick={toggleExitPopup}>Выйти</SimpleButton>
      </div>}
    </>
  )

}
