import * as React from 'react';
import {MainButton} from "../cmp/Button";
import {SubtitleText, SmallText} from "../cmp/Text";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ethers} from "ethers";
import {BackHeader} from "../cmp/BackHeader";
import {useLocalWallet} from "../context/WalletContext";
import {useCallback} from "react";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'white',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 10px',
    background: '#FFFAF0',
    position: 'relative',
  },
  backButton: {
    cursor: 'pointer',
  },
  titleContainer: {
    padding: '20px',
    background: '#FFFAF0',
    textAlign: 'center',
  },
  seedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
  },
  seedItem: {
    borderRadius: '20px',
    padding: '10px 20px',
    margin: '5px',
    background: '#f0f0f0',
    border: '1px solid #ccc',
  },
  bottomContainer: {
    marginTop: 'auto',
    padding: '20px',
    textAlign: 'center',
  },
  copyButton: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
    marginBottom: '15px',
  },
  textAreaStyle: {
    width: '100%', // Растягивает по ширине
    padding: '10px', // Добавляет немного отступа внутри
    marginTop: '20px', // Сдвигает вниз
    borderRadius: '5px', // Делает углы слегка закругленными
    border: '1px solid #ccc', // Устанавливает цвет границы
    resize: 'none' // Запрещает пользователю изменять размер
  },
  errorPlaceholder: {
    height: '20px',
    color: 'transparent', // Зарезервированное место под ошибку
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '15px',
  },
};

export default function CreateWalletPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState('');
  const {setLocalWallet} = useLocalWallet();


  const handleImport = () => {
    try {
      const key = inputValue.trim();

      console.log("Key length:", key.length);
      console.log("Is hex string:", ethers.isHexString(key));

      if (ethers.Mnemonic.isValidMnemonic(key)) {
        // Ваш код для сид-фразы
        setLocalWallet({seedPhrase:key, derivationPath: process.env.REACT_APP_DERIVATION_PATH});
        navigate('/wallet/create/pin?xtoken=' + searchParams.get("xtoken"));
      } else if (ethers.isHexString(key, 32)) {
        // Ваш код для 32-байтного приватного ключа
        const wallet = new ethers.Wallet(key);
        setLocalWallet({seedPhrase:wallet.mnemonic.phrase, derivationPath: process.env.REACT_APP_DERIVATION_PATH});
        navigate('/wallet/create/pin?xtoken=' + searchParams.get("xtoken"));
      } else {
        try {
          const wallet = new ethers.Wallet(key);
          setLocalWallet({privateKey: wallet.privateKey});
          navigate('/wallet/create/pin?xtoken=' + searchParams.get("xtoken"));
        } catch (err) {
          console.log("err", err);
          setError('Введите корректную сид-фразу или приватный ключ.');
        }
      }
    } catch (err) {
      console.error(err);
      setError('Произошла ошибка при валидации. Проверьте введенные данные.');
    }
  };

  const inputHandler = useCallback((e) => {
    setInputValue(e.target.value);
    setError(null);
  },[]);

  return (
    <div style={styles.container}>
      <BackHeader>Шаг 1: Импорт кошелька</BackHeader>
      <div style={styles.titleContainer}>
        <SubtitleText>Запишите или скопируйте вашу секретную фразу или приватный ключ</SubtitleText>
        <br/>
      </div>
      <div style={styles.seedContainer}>
        <SubtitleText>Введите вашу сид-фразу или приватный ключ</SubtitleText>
        <textarea
          style={styles.textAreaStyle}
          rows={20}
          value={inputValue}
          onChange={inputHandler}
          placeholder="Сид-фраза или приватный ключ"
        />
      </div>
      <div style={styles.bottomContainer}>
        <div style={error ? styles.error : styles.errorPlaceholder}>{error}</div>

        <MainButton onClick={handleImport}>
          Импортировать
        </MainButton>
      </div>
    </div>
  );
}

