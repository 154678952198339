export const notifyMarketplaceAboutNewWallet = async (address, xToken) => {
  try {
    const response = await fetch('https://api-mobile.tuvis.world/api/app-discount/client/crypto-wallet?address=' + address, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Token': xToken
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
    } else {
      console.error('Произошла ошибка запроса:', response.status);
    }

  } catch (error) {
    console.error('Произошла ошибка:', error);
  }
};

export async function getBSOBonuses(address){
  try {
    const response = await fetch('https://bonuses.bscosmo.io/getBonusesTransactionByBuyerAddress?pageSize=100&address='+address);
    if (response.ok) {
      return await response.json();
    } else {
      console.error('Произошла ошибка запроса:', response.status);
    }
  } catch (error) {
    console.error('Произошла ошибка:', error);
  }
}
