export default function Balance({balance, coin, style}) {
  function showBalance(){
    try {
      if (!balance) return 0;
      if (parseFloat(balance) === 0) return 0;

      let ret = parseFloat(balance).toFixed(8);
      //remove trailing zeros
      while (ret[ret.length - 1] === '0') {
        ret = ret.slice(0, ret.length - 1);
      }
      return ret;
    } catch (e) {
      return 0;
    }
  }

  return (
    <span style={style}>
      {showBalance()}&nbsp;{coin.code}
    </span>
  );
}
