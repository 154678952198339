import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import {MainButton, SimpleButton} from "../cmp/Button";
import Fade from "../cmp/Fade";
import React from "react";

export default function Popup({onClose, onOk, okButtonText, children, header, isOkEnabled}) {

  const okHandler = () => {
    if (isOkEnabled) {
      onOk();
    }
  }

  return (
    <>
      <Fade/>
      <div style={{
        display: "flex",
        backgroundColor: "#fff",
        flexDirection: "column",
        position: "absolute",
        zIndex: "2",
        top: '20px',
        left: '20px',
        right: '20px',
        bottom: '20px',
        borderRadius: '20px',
        border: '1px solid #ccc',
        padding: '20px',
      }}>
        <div style={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "20px"
        }}>{header}</div>
        {children}
        <span style={{marginTop: 'auto'}}>
          {onOk && <MainButton disabled={!isOkEnabled} onClick={okHandler}>{okButtonText || 'Apply'}</MainButton>}
          <SimpleButton onClick={onClose}>Close</SimpleButton>
        </span>
      </div>
    </>
  );
}
