import React, {useState} from 'react';

const styles = {
  tooltip: {
    position: 'relative',
    top: "-10px",
    left: "-12px"
  },
  tooltipContent: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    borderRadius: '5px',
    transform: 'translateX(-50%)',
    backgroundColor: 'black',
    color: 'white',
    padding: '5px',
    zIndex: 1,
  },
  tooltipArrow: {
    position: 'absolute',
    bottom: 'calc(100% - 5px)',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid black',
  },
};

const Tooltip = ({ text, visible}) => {

  return (
    visible && (
      <span style={styles.tooltip}>
        <div style={styles.tooltipContent}>{text}</div>
        <div style={styles.tooltipArrow}></div>
      </span>
    )
  );
};

export default Tooltip;
