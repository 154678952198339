import React, {useState} from "react";
import useAsyncEffect from "use-async-effect";
import getRate from "../util/rates";


const styles = {
  usdEquivalent: {
    color: 'grey',
    fontSize: '14px'
  },
}
export default function ({network, coin, to, amount, toFixed}) {
  const [rate, setRate] = useState(0);

  useAsyncEffect(async () => {
    if (!coin || !network || !coin.code) {
      setRate(0)
      return;
    }

    ///console.log("crossrate:", coin, coin.main ? network.tokenWrapperAddress : coin.address, to || network.usdtAddress)
    const rate = await getRate(network, coin.main ? network.tokenWrapperAddress : coin.address, to || network.usdtAddress);
    //console.log("crossrate:", rate, coin.code)

    const val = (amount * rate).toFixed(toFixed || 2)
    setRate(isNaN(val) ? 0 : val);

  }, [coin, network, to, amount]);


  return (
    <div style={styles.usdEquivalent}>{rate}&nbsp;{to || "USD"}</div>
  )
}
