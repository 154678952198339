import QRCode from "react-qr-code";
import {AddressLine} from "../cmp/AddressLine";
import Popup from "./Popup";
import {SmallText, TitleText} from "../cmp/Text";
import {Input} from "../cmp/Input";
import React from "react";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}

export default function SettingsPopup({onClose, onOk, okButtonText, localWallet}) {
  const [path, setPath] = React.useState(localWallet.derivationPath);

  const handlePathChange = (e) => {
    setPath(e.target.value);
  }

  const handleOk = () => {
    onOk({path});
  }

  return (
    <Popup header="Настройки" onClose={onClose} onOk={handleOk} okButtonText={okButtonText} isOkEnabled={true}>
      {localWallet.seedPhrase && <div style={styles.container}>
        <TitleText>Сабаккаунты</TitleText>
        <SmallText>К каждой сидфразе можно сгенерировать бесконечное количество адресов, если вы хотите пользоватся кошельком отличным от кошелька по умолчанию m/44'/60'/0'/0 то измените путь, в метамаске дополнительные аккаунты создаются по принципу добавления /n к стандартному пути, где n это порядковый номер сабаккаунта</SmallText>
        <Input
          label={"Derivation paths"}
          type="text"
          placeholder="Enter derivation paths"
          value={path}
          onChange={handlePathChange}
        />
        <div style={{textAlign:"left", width:"100%", cursor:"pointer", color:"lightblue"}}>Сбросить</div>
      </div>}
    </Popup>
  );
}
