import React, {useContext, useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {CircleButton, MainButton, SimpleButton} from '../cmp/Button';
import {useLocalWallet} from "../context/WalletContext";
import {
  ERC20_ABI,
  etherWallet,
  getBEP20TransactionsByToken,
  getBNBTransactions,
  getUserTokens,
  NETWORKS
} from "../util/crypto";
import {useNavigate, useSearchParams} from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import DepositPopup from "../popup/DepositPopup";
import {CoinAvatar} from "../cmp/CoinAvatar";
import {AddressLine} from "../cmp/AddressLine";
import ImportTokensPopup from "../popup/ImportTokensPopup";
import WithdrawPopup from "../popup/WithdrawPopup";
import SwapPopup from "../popup/SwapPopup";
import formatDate from "@bitty/format-date";
import depositIcon from "../assets/icons/icons8-deposit-48.png";
import withdrawalIcon from "../assets/icons/icons8-withdrawal-48.png";
import logo from "../assets/logo200.png";
import CrossRate from "../cmp/CrossRate";
import BurgerMenu from "../cmp/BurgerMenu";
import ExportSidPopup from "../popup/ExportSidPopup";
import Balance from "../cmp/Balance";
import Loading from "../cmp/Loading";
import {getBSOBonuses} from "../util/marketplace";



const styles = {
  container: {
    height: '100vh',
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'min-content min-content min-content min-content min-content 1fr min-content',
    overflow: 'scroll',
    maxWidth: '100vw',

    background: '#f0f0f0',
    gap: '0',
  },

  fixedSizeContainer: {
    minHeight: '20px',
    maxHeight: '20vh',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },

  containerWithScroll: {
    wordBreak: 'break-word',
    overflow: 'auto',
    boxSizing: 'border-box',
  },

  networkContainer: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: '1px solid #ccc',
    alignItems:"center",
  },
  addressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    padding: '20px 0 10px',
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
  mainBalance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    justifyContent: 'center',
    color: '#000',
    fontSize: '34px',
    margin: '10px 0'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%'
  },
  tokenListContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    padding: '10px',
    overflowY: 'auto',
    height: '100%',
  },
  tokenItem: {
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    maxWidth: '100vw',
    paddingTop: '10px',
  },
  tokenListHeader: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    maxWidth: '100vw',
  },
  tokenListHeaderItem: {
    paddingLeft: '20px',
    paddingRight: '20px',
    lineHeight: "3"
  },
  tokenListHeaderItemActive: {
    color: '#0376c9',
    paddingRight: '20px',
    borderBottom: '2px solid #0376c9',
  },
  unicodeIcon: {
    fontSize: '20px',
    marginRight: '5px',
    fontWeight: 'bold',
  },
  linkButtons: {
    color: 'blue',
    cursor: 'pointer',
  }
};


export default function CryptoDashboard() {
  const navigate = useNavigate();
  const [searchParams,setSearchParams] = useSearchParams();
  const { localWallet } = useLocalWallet();
  const [wallet, setWallet] = useState();
  const [coinBalance, setCoinBalance] = useState({});
  const [coins, setCoins] = useState([]);
  const [network, setNetwork] = useState(NETWORKS[0]);
  const [isDepositOpened, setIsDepositOpened] = useState(false);
  const [isAddTokenOpened, setIsAddTokenOpened] = useState(false);
  const [isWithdrawOpened, setIsWithdrawOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isSwapOpened, setIsSwapOpened] = useState(false);
  const [activeCoin, setActiveCoin] = useState({});
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [bonusHistory, setBonusHistory] = useState("loading");
  const [isExportSidOpened, setIsExportSidOpened] = useState(false);
  const [derivationPath, setDerivationPath] = useState(process.env.REACT_APP_DERIVATION_PATH);

  useEffect(() => {
    try {
      if (!localWallet) {
        navigate('/');
        return;
      }
      if (!network) return;

      if (searchParams.get("dest") === "export-sid") {
        searchParams.delete("dest");
        setSearchParams(searchParams);
        setIsExportSidOpened(true);
      }

      if (localStorage.getItem("SETTINGS_DERIVATION_PATH")) {
        setDerivationPath(localStorage.getItem("SETTINGS_DERIVATION_PATH"));
      }


      const mnemonicWallet = etherWallet(localWallet)
      if (!mnemonicWallet) {
        navigate('/');
        return;
      }

      const provider = network.provider;
      const signer = mnemonicWallet.connect(provider);
      setWallet(signer);

      getBSOBonuses(/*"0x977320c4D4224c4C2dAd2b8c711da9F0657A26Bd"*/signer.address).then((bonuses) => {
        setBonusHistory(bonuses);
      });
    } catch (e) {
      alert(e)
    }

  }, [localWallet, network, derivationPath]);

  function refreshDashBoard() {
    setDerivationPath(localStorage.getItem("SETTINGS_DERIVATION_PATH"));
  }

  useAsyncEffect(async () => {
    if (!wallet) return;
    if (!network) return;
    if (!coins[0].code) return;

    coinBalance[coins[0].code] = ethers.formatEther(await network.provider.getBalance(wallet.address));

    setActiveCoin(coins[0]);
    setCoinBalance({...coinBalance});
  }, [wallet, coins, network]);

  useEffect(() => {
    let  coins  = localStorage.getItem("USER_COINS") || 'ww';
    try {
      coins = JSON.parse(coins);
    } catch (e) {
      coins = network.initialCoins;
      localStorage.setItem("USER_COINS", JSON.stringify(coins));
    }

    setCoins([{main:true, code: network.token, icon: require(`../assets/icons/${network.token}.png`)}, ...coins]);
  }, [network])

  useAsyncEffect(async () => {
    if (!wallet) return;

    const data = coinBalance || {};
    for (let coin of coins) {
      try {
        if (!coin.address || coin.main) continue;
        const contract = new ethers.Contract(coin.address, ERC20_ABI, wallet);
        data[coin.code] = ethers.formatUnits(await contract.balanceOf(wallet.address), coin.decimal);
      } catch (e) {
        data[coin.code] = '';
      }
    }
    setCoinBalance(data);
  }, [wallet, coins]);


  const toggleDepositPopup = () => {
    setIsDepositOpened(!isDepositOpened);
  };
  const toggleAddTokenPopup = () => {
    setIsAddTokenOpened(!isAddTokenOpened);
  };

  const toggleExportSidPopup = () => {
    setIsExportSidOpened(!isExportSidOpened);
  };

  const toggleWithdrawPopup = () => {
    setIsWithdrawOpened(!isWithdrawOpened);
  };

  const toggleSwapPopup = () => {
    setIsSwapOpened(!isSwapOpened);
  };

  const setActiveTabHandle = (tab) => {
    setActiveTab(tab);
  };

  const refresh = async () => {
    const coins = await getUserTokens(network, wallet.address);
    const unique = {};
    for (const coin of coins) {
      unique[coin.contractAddress] = coin;
    }

    const tokens = [];

    for (let key in unique) {
      if (unique.hasOwnProperty(key)) {
        tokens.push({
          address: unique[key].contractAddress,
          code: unique[key].tokenSymbol,
          decimals: parseInt(unique[key].tokenDecimal)
        })
      }
    }

    addNewToken(tokens);

    setNetwork({...network})
    setCoinBalance({})
  };

  const addNewTokenHandler = (token) => {
    addNewToken([token])
    toggleAddTokenPopup();
  }
  const addNewToken = (tokens) => {
    tokens = tokens.filter((t) => !coins.find((c) => c.address?.toLowerCase() === t.address.toLowerCase()));
    const coinsLocal = [...coins, ...tokens];
    console.log("coinsLocal", coinsLocal)
    localStorage.setItem("USER_COINS", JSON.stringify(coinsLocal.filter((c) => !c.main)));
    setCoins(coinsLocal);
  };

  const selectCoin = (coin) => {
    setTransactionHistory("loading");
    if (!coin.main) {
      getBEP20TransactionsByToken(network, wallet.address, coin.address).then((transactions) => {
        if (transactions.message === "NOTOK") {
          setTransactionHistory({error: transactions.result})
        } else {
          setTransactionHistory(transactions?.sort((a, b) => b.timeStamp - a.timeStamp));
        }
      });
    } else {
      getBNBTransactions(network, wallet.address).then((transactions) => {
        if (transactions.message === "NOTOK") {
          setTransactionHistory({error: transactions.result})
        } else {
          setTransactionHistory(transactions?.sort((a, b) => b.timeStamp - a.timeStamp));
        }
      });
    }
    setActiveTab(1)
    setActiveCoin(coin);
  };

  const copyToClipboard = (s) => {
    navigator.clipboard.writeText(s);
  };

  const changeNetworkHandler = (e) => {
    setNetwork(NETWORKS.findLast((n) => n.name === e.target.value));
  }


  return (
    <>
      {isDepositOpened && <DepositPopup address={wallet?.address} onClose={toggleDepositPopup}/>}
      {isExportSidOpened && <ExportSidPopup seedPhrase={localWallet} onClose={toggleExportSidPopup}/>}
      {isAddTokenOpened && <ImportTokensPopup provider={network.provider} onOk={addNewTokenHandler} okButtonText={'Добавить'}
                                              onClose={toggleAddTokenPopup}/>}
      {isWithdrawOpened && <WithdrawPopup wallet={wallet} onOk={refresh} network={network} provider={network.provider} startedCoin={activeCoin} coins={coins} coinsBalance={coinBalance} onClose={toggleWithdrawPopup}/>}
      {isSwapOpened && <SwapPopup address={wallet?.address} coins={coins} coinsBalance={coinBalance} onClose={toggleSwapPopup}/>}
      <div style={styles.container}>
        <div style={{...styles.fixedSizeContainer, ...styles.networkContainer}}>
          <div><img src={logo} width={30} height={30}/></div>
          <select style={{borderRadius: '10px', padding: '5px', border: '1px solid #ccc'}} onChange={changeNetworkHandler}>
            {NETWORKS.map((network) => (
              <option key={network.name} value={network.name}>{network.name}</option>
            ))}
          </select>
          <BurgerMenu refreshDashBoard={refreshDashBoard}/>
        </div>
        <div style={{...styles.fixedSizeContainer, ...styles.addressContainer}}>
          <AddressLine address={wallet?.address}/>
        </div>
        <div style={{...styles.fixedSizeContainer, ...styles.balanceContainer}}>
          <div style={styles.mainBalance}>
            <Balance balance={coinBalance[activeCoin.code]} coin={activeCoin}/>
            <CrossRate coin={activeCoin} network={network} amount={coinBalance[activeCoin.code]}/>
          </div>
        </div>

        {/*<div style={{...styles.fixedSizeContainer}}>*/}
        {/*  {activeCoin.address}*/}
        {/*</div>*/}

        <div style={{...styles.fixedSizeContainer, ...styles.buttonContainer}}>
          <CircleButton name={'Получить'} onClick={toggleDepositPopup}>️&#8595;</CircleButton>
          <CircleButton name={'Отправить'} onClick={toggleWithdrawPopup}>&#8593;️</CircleButton>
          <CircleButton name={'Обменять'} onClick={toggleSwapPopup}>&#8651;️</CircleButton>
        </div>
        <div style={{...styles.fixedSizeContainer, ...styles.tokenListHeader}}>
          <div onClick={setActiveTabHandle.bind(this, 0)} style={{...styles.tokenListHeaderItem, ...(activeTab===0?styles.tokenListHeaderItemActive:{})}}>Токены</div>
          <div onClick={setActiveTabHandle.bind(this, 1)} style={{...styles.tokenListHeaderItem, ...(activeTab===1?styles.tokenListHeaderItemActive:{})}}>Активность</div>
          <div onClick={setActiveTabHandle.bind(this, 2)} style={{...styles.tokenListHeaderItem, ...(activeTab===2?styles.tokenListHeaderItemActive:{})}}>Бонусы</div>
        </div>
        <div style={{...styles.containerWithScroll, ...styles.tokenListContainer, display:activeTab===0?'':'none'}}>
          {coins.map((coin) => (
            <div style={styles.tokenItem} key={coin.address + coin.main} onClick={selectCoin.bind(this, coin)}>
              <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <span>
                  <CoinAvatar coin={coin}/>
                </span>
                <div style={{display:"flex", flexDirection:"column"}}>
                  <span style={{fontWeight: "500"}}>&nbsp;{coin.code}</span>
                  <Balance style={{color: "#676565"}} balance={coinBalance[coin.code]} coin={coin}/>
                </div>
              </span>
              <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <CrossRate coin={coin} amount={coinBalance[coin.code]} network={network}/>
              </span>
            </div>
          ))}
        </div>
        <div style={{...styles.containerWithScroll, ...styles.tokenListContainer, display:activeTab===1?'':'none'}}>
          {transactionHistory === "loading" && <div style={{padding: "100px", textAlign:"center"}}><Loading/></div>}
          {transactionHistory?.error && <div style={{padding: "100px", textAlign:"center"}}>Слишком частые запросы. Попробуйте немного позже.</div>}
          {!transactionHistory?.error && transactionHistory !== "loading" && !transactionHistory?.length && <div style={{padding: "50px", textAlign:"center"}}>Нет транзакций</div>}
          {!transactionHistory?.error && transactionHistory !== "loading" && transactionHistory?.length > 0 && transactionHistory.map((tx) => (
            <div style={styles.tokenItem} key={tx.hash} onClick={copyToClipboard.bind(this, tx.hash)}>
              <span  style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  {tx.to.toLowerCase() === wallet.address.toLowerCase() && <img style={{width:"24px", height:"24px"}} src={depositIcon}/>}
                  {tx.to.toLowerCase() !== wallet.address.toLowerCase() && <img style={{width:"24px", height:"24px"}} src={withdrawalIcon}/>}
                </span>
                <span style={{display: 'flex', justifyContent: 'start', alignItems: 'start',  flexDirection:"column"}}>
                  <AddressLine address={tx.hash.substring(0, 7) + "..." + tx.hash.substring(58)}/>
                  <span style={{color: "gray", fontSize:"12px"}}>&nbsp;{formatDate(new Date(parseInt(tx.timeStamp) * 1000), "DD.MM.YY HH:MM")}</span>
                </span>
              </span>
              <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'end', flexDirection:"column"}}>
                <Balance balance={ethers.formatUnits(tx.value, parseInt(tx.tokenDecimal || 18))} coin={activeCoin}/>
                <CrossRate coin={activeCoin} amount={ethers.formatUnits(tx.value, parseInt(tx.tokenDecimal || 18))} network={network}/>
              </span>
            </div>
          ))}
        </div>
        <div style={{...styles.containerWithScroll, ...styles.tokenListContainer, display:activeTab===2?'':'none'}}>
          {bonusHistory === "loading" && <div style={{padding: "100px", textAlign:"center"}}><Loading/></div>}
          {bonusHistory !== "loading" && !bonusHistory?.transactions?.length && <div style={{padding: "50px", textAlign:"center"}}>У вас еще нет бонусов, покупайте товары в нашем магазине</div>}
          {bonusHistory !== "loading" && bonusHistory?.transactions?.length > 0 && bonusHistory.transactions.map((tx) => (
            <div style={styles.tokenItem} key={tx.hash} onClick={copyToClipboard.bind(this, tx.hash)}>
              <span  style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <img style={{width:"24px", height:"24px"}} src={depositIcon}/>
                </span>
                <span style={{display: 'flex', justifyContent: 'start', alignItems: 'start',  flexDirection:"column"}}>
                  {tx.hash && <AddressLine address={tx.hash?.substring(0, 7) + "..." + tx.hash.substring(58)}/>}
                  <span style={{color: "gray", fontSize:"12px"}}>&nbsp;{formatDate(new Date(tx.created_at), "DD.MM.YY HH:MM")}</span>
                </span>
              </span>
              <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'end', flexDirection:"column"}}>
                <Balance balance={tx.bonuses_amount_in_bso} coin={{code:"BSO", main:false}}/>
                <CrossRate coin={{code:"BSO", main:false}} amount={tx.bonuses_amount_in_bso} network={network}/>
              </span>
            </div>
          ))}
        </div>
        {(activeTab===0 || activeTab===1) &&
          <div style={{...styles.fixedSizeContainer, ...styles.tokenListHeader}}>
            <a style={styles.linkButtons} onClick={refresh}><span style={styles.unicodeIcon}>&#10227;</span>{'Обновить'}
            </a>
            <br/>
            <a style={styles.linkButtons} onClick={toggleAddTokenPopup}><span
              style={styles.unicodeIcon}>+</span>{'Импортировать токены'}</a>
          </div>
        }
        {activeTab===2 &&
          <div style={{...styles.fixedSizeContainer, ...styles.tokenListHeader}}>
            <a style={styles.linkButtons} target="_blank" href={"https://bscosmo.io/buyer?address=" + wallet.address }><span style={styles.unicodeIcon}></span>{'Перейти на вебсайт'}</a>
          </div>
        }
      </div>
    </>
  );


};



