import * as React from "react";
import Tooltip from "./Tooltip";

const styles = {
  unicodeIcon: {
    fontSize: '20px',
    marginRight: '5px',
    fontWeight: 'bold',
  },
  addressContainer: {
    whiteSpace: 'nowrap',
    fontSize: '3vw',
  }
}
export function AddressLine({address}) {
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  let tooltip = null;

  const copyToClipboard = (address) => {
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 2000);
    navigator.clipboard.writeText(address);
    setCopySuccess(true);
  };

  return (
    <div style={styles.addressContainer} onClick={() => copyToClipboard(address)}>
      <span>{address}&nbsp;</span>
      <span style={{...styles.unicodeIcon, color: copySuccess?"#13b396":null}}>
        {copySuccess && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
        </svg>}
        {!copySuccess && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
        </svg>}
      </span>
      <Tooltip text={"Скопировано"} visible={tooltipVisible}></Tooltip>
    </div>
  )
}
