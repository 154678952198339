import * as React from 'react';
import { MainButton } from "../cmp/Button";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { BackHeader } from "../cmp/BackHeader";
import { useLocalWallet } from "../context/WalletContext";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'white',
  },
  titleContainer: {
    padding: '20px',
    background: '#FFFAF0',
    textAlign: 'center',
  },
  poolContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '20px',
  },
  item: {
    borderRadius: '20px',
    padding: '10px 20px',
    margin: '5px',
    background: '#f0f0f0',
    border: '1px solid #ccc',
    cursor: 'pointer',
  },
  selectedContainer: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  selectedWord: {
    borderRadius: '20px',
    padding: '10px 20px',
    margin: '5px',
    background: 'blue',
    border: '1px solid #ccc',
    color: 'white',
    cursor: 'pointer',
  },
  bottomContainer: {
    padding: '20px',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '15px',
  },
};

export default function ConfirmSeedPhrasePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { localWallet } = useLocalWallet();
  const [shuffledSeedPhrase, setShuffledSeedPhrase] = React.useState([...(localWallet.seedPhrase.split(' '))].sort(() => Math.random() - 0.5));
  const [selectedWords, setSelectedWords] = React.useState([]);
  const [isValid, setIsValid] = React.useState(true);

  const handleWordClick = (word) => {
    if (selectedWords.includes(word)) return;
    setSelectedWords([...selectedWords, word]);
    setShuffledSeedPhrase(shuffledSeedPhrase.filter(w => w !== word));
    setIsValid(true);
  };

  const handleSelectedWordClick = (word) => {
    setSelectedWords(selectedWords.filter(w => w !== word));
    setShuffledSeedPhrase([...shuffledSeedPhrase, word]);
    setIsValid(true);
  };

  const handleConfirm = () => {
    if (selectedWords.join(' ') === localWallet.seedPhrase) {
      navigate('/wallet/create/pin?xtoken=' + searchParams.get("xtoken"));
    } else {
      setIsValid(false);
    }
  };

  return (
    <div style={styles.container}>
      <BackHeader>Шаг 2: Подтвердите вашу секретную фразу</BackHeader>
      <div style={styles.titleContainer}>
        <p>Нажмите на слова в том порядке, в котором они были представлены, чтобы подтвердить свою секретную фразу.</p>
      </div>
      <div style={styles.selectedContainer}>
        {selectedWords.map((word, index) => (
          <div key={index} style={styles.selectedWord} onClick={() => handleSelectedWordClick(word)}>
            {word}
          </div>
        ))}
      </div>
      <div style={styles.poolContainer}>
        {shuffledSeedPhrase.map((word, index) => (
          <div key={index} style={styles.item} onClick={() => handleWordClick(word)}>
            {word}
          </div>
        ))}
      </div>
      <div style={styles.bottomContainer}>
        {!isValid && <div style={styles.error}>Слова введены неверно, пожалуйста, попробуйте еще раз.</div>}
        <MainButton onClick={handleConfirm}>
          Подтвердить
        </MainButton>
      </div>
    </div>
  );
}
