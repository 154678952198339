import * as React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BasicLayout from "./layout/BasicLayout";
import WelcomePage from "./page/WelcomePage";
import NotFoundPage from "./page/NotFoundPage";
import CreateWalletPage from "./page/CreateWalletPage";
import CreateRepeatWalletPage from "./page/ConfirmSeedPhrasePage";
import ConfirmSeedPhrasePage from "./page/ConfirmSeedPhrasePage";
import {WalletProvider} from "./context/WalletContext";
import CreatePinCodePage from "./page/CreatePinCodePage";
import ImportWalletPage from "./page/ImportWalletPage";
import DashboardPage from "./page/DashboardPage";
import EnterPinCodePage from "./page/EnterPinCodePage";


function App() {
  return (
    <BrowserRouter>
      <WalletProvider>
        <Routes>
          <Route path="/" element={<BasicLayout/>}>
            <Route index element={<WelcomePage/>}/>
            <Route path={'/wallet/create'} element={<CreateWalletPage/>}/>
            <Route path={'/wallet/import'} element={<ImportWalletPage/>}/>
            <Route path={'/wallet/confirm'} element={<ConfirmSeedPhrasePage/>}/>
            <Route path={'/wallet/create/pin'} element={<CreatePinCodePage/>}/>
            <Route path={'/dashboard'} element={<DashboardPage/>}/>
            <Route path={'/pin'} element={<EnterPinCodePage/>}/>
            <Route path="*" element={<NotFoundPage/>}/>
          </Route>
        </Routes>
      </WalletProvider>
    </BrowserRouter>
  );
}

export default App;
